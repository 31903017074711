import { getPages, getPage, getItems, convertData } from "@/lib/helpers/directus";

export const generatePathnames = async (type = 'all', return_type = 'object', includeWorkAreaPages = true) => {
    const pathnames = {};
    let pages = null;

    // Get all static pages
    if (type === 'static' || type === 'all' || type === 'sitemap' || type === 'all_with_expertise') {
        try {
            if (type === 'sitemap') {
                pages = await getPages({
                    fields: [
                        'seo.*',
                        'date_updated'
                    ]
                });
            } else {
                pages = await getPages({
                    fields: [
                        'expertise',
                    ]
                });
            }
        } catch (error) {
            console.error('error getting pages', error);
        }

        if (!pages) {
            return pathnames;
        }

        // Generate all static pages pathnames
        try {
            pages.map((page) => {
                pathnames[page.permalink] = {
                    id: page.id,
                    title: page.title,
                    slug: page.permalink,
                }

                if (type === 'all_with_expertise') {
                    pathnames[page.permalink].expertise = page.expertise;
                }

                if (type === 'sitemap') {
                    pathnames[page.permalink].seo = page.seo ?? null;
                    pathnames[page.permalink].lastModified = new Date(page.date_updated);
                }
            });
        } catch (error) {
            console.error('generatePathnames', error);
        }
    }

    // Generate all SEO pathnames
    if (type === 'dynamic' || type === 'all' || type === 'sitemap' || type === 'all_with_expertise') {
        if (includeWorkAreaPages) {
            try {
                let page = null;
                if (type === 'sitemap') {
                    page = await getPage('werkgebieden-loodgieter', false, {
                        fields: [
                            'seo.*',
                            'date_updated'
                        ]
                    });
                } else {
                    page = await getPage('werkgebieden-loodgieter');
                }

                pathnames[page.permalink] = {
                    id: page.id,
                    title: page.title,
                    slug: page.permalink,
                }

                if (type === 'all_with_expertise') {
                    pathnames[page.permalink].expertise = page.expertise;
                }

                if (type === 'sitemap') {
                    pathnames[page.permalink].seo = page.seo ?? null;
                    pathnames[page.permalink].lastModified = new Date(page.date_updated);
                }
            } catch (error) {
                console.error('error getting dynamic pages', error);
            }

            try {
                let page = null;
                if (type === 'sitemap') {
                    page = await getPage('werkgebieden-riool-ontstoppen', false, {
                        fields: [
                            'seo.*',
                            'date_updated'
                        ]
                    });
                } else {
                    page = await getPage('werkgebieden-riool-ontstoppen', false);
                }

                pathnames[page.permalink] = {
                    id: page.id,
                    title: page.title,
                    slug: page.permalink,
                }

                if (type === 'all_with_expertise') {
                    pathnames[page.permalink].expertise = page.expertise;
                }

                if (type === 'sitemap') {
                    pathnames[page.permalink].seo = page.seo ?? null;
                    pathnames[page.permalink].lastModified = new Date(page.date_updated);
                }
            } catch (error) {
                console.error('error getting dynamic pages', error);
            }
        }

        try {
            let locations = await getItems('seo_locations', {
                fields: [
                    '*',
                    {
                        types_id: [
                            '*',
                            {
                                seo_types_id: [
                                    '*',
                                ],
                            }
                        ]
                    }
                ]
            });
        
            locations = await convertData(locations, [
                {
                    from: 'types_id.seo_types_id',
                    to: 'types',
                }
            ]);
            
            locations.map((location) => {
                location.types.map((locationType) => {
                    pathnames[`/${locationType.slug}-${location.slug}`] = {
                        id: `${locationType.slug}-${location.slug}`,
                        title: `${locationType.title} ${location.title}`,
                        slug: `/${locationType.slug}-${location.slug}`,
                    }

                    if (type === 'all_with_expertise') {
                        pathnames[`/${locationType.slug}-${location.slug}`].expertise = location.expertise;
                    }

                    if (type === 'sitemap') {
                        pathnames[`/${locationType.slug}-${location.slug}`].seo = location.seo ?? null;
                        pathnames[`/${locationType.slug}-${location.slug}`].lastModified = new Date();
                    }
                });
            });
        } catch (error) {
            console.error('error getting dynamic pages', error);
        }
    }

    if (return_type === 'array') {
        return Object.values(pathnames);
    }

    return pathnames;
}
import { directus, readItems } from "@/lib/helpers/directus";

import { notFound } from 'next/navigation';

export const getPages = async (filterObj = {}) => {
    filterObj = {
        filter: {
            status: {
                _eq: 'published',
            },
            
            ...filterObj.filter || {},
        },
        fields: [
            'id',
            'title',
            'permalink',

            ...filterObj.fields || [],
        ],
    };

    try {
        const pages = await directus.request(readItems('pages', filterObj));

        if (!pages.length) {
            return null;
        }

        return pages;
    } catch (error) {
        console.error('error', error);

        return null;
    }
};

export const getPage = async (slug, notFoundPage = false, filterObj = {}) => {
    filterObj = {
        filter: {
            _and: [
                {
                    permalink: {
                        _eq: '/' + slug,
                    },
                    status: {
                        _eq: 'published',
                    },

                    ...filterObj.filter || {},
                },
            ]
        },
        fields: [
            '*',
            {
                blocks: [
                    '*',
                    {
                        item: [
                            '*',
                            {
                                button_group: [
                                    '*',
                                    {
                                        buttons: [
                                            '*',
                                            {
                                                block_button_id: [
                                                    '*',
                                                    {
                                                        page: [
                                                            'permalink',
                                                        ],
                                                        post: [
                                                            'slug',
                                                        ],
                                                    }
                                                ],
                                            },
                                        ],
                                    },
                                ],
                                form: [
                                    '*',
                                ],
                                services: [
                                    '*',
                                ],
                                cards: [
                                    '*',
                                ],
                                text_blocks: [
                                    '*',
                                    {
                                        item: [
                                            '*',
                                        ]
                                    }
                                ],
                                children: [
                                    '*',
                                    {
                                        item: [
                                            '*',
                                        ]
                                    }
                                ],
                            }
                        ],
                    },
                ],
            },

            ...filterObj.fields || [],
        ]
    };


    try {
        const page = await directus.request(readItems('pages', filterObj));

        if(!page.length && !notFoundPage) return notFound();

        return page[0];
    } catch (error) {
        if (notFoundPage) {
            return null;
        } else {
            return notFound();
        }
    }
}